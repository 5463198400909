export const HowToBlockSlider = {
  init() {
    if (!this.isRendered()) {
      return;
    }
    this.getElement('left').addEventListener(
      'click',
      this.slideLeft.bind(this),
    );
    this.getElement('right').addEventListener(
      'click',
      this.slideRight.bind(this),
    );
    this.options.activeSlide = 0;
    this.options.slidesCount = this.getSlideItems().length;
    this.initArrows();
    this.initActiveItem();
    this.initIllustration();
  },
  options: {
    selectors: {
      container: '.rwl-read-slider__inner',
      illustration: '.rwl-read-slider__illustration',
      items: '.rwl-read-slide',
      left: '.rwl-read-slider__nav-item.rwl-read-slider__nav-item--prev',
      right: '.rwl-read-slider__nav-item.rwl-read-slider__nav-item--next',
    },
    activeSlide: null,
    slidesCount: null,
  },
  isRendered() {
    return this.getElement('container') !== null;
  },
  getSlideItems() {
    return document.querySelectorAll(this.options.selectors.items);
  },
  getElement(elementName) {
    return document.querySelector(this.options.selectors[elementName]);
  },
  slideRight() {
    this.options.activeSlide++;
    this.moveSliderWrapper();
  },
  slideLeft() {
    this.options.activeSlide--;
    this.moveSliderWrapper();
  },
  initIllustration() {
    this.getElement('illustration').classList.toggle(
      'is-hidden',
      this.options.activeSlide !== 0,
    );
  },
  moveSliderWrapper() {
    this.initArrows();
    this.initActiveItem();
    this.initIllustration();

    const slideWidth = this.getSlideItems()[0].offsetWidth;
    const translate = this.options.activeSlide * slideWidth;

    this.getElement('container').style.transform =
      document.dir === 'rtl'
        ? 'translateX(' + translate + 'px)'
        : 'translateX(-' + translate + 'px)';
  },
  initActiveItem() {
    this.getSlideItems().forEach((item, index) => {
      if (index === this.options.activeSlide) {
        item.classList.add('is-active');
        return;
      }
      item.classList.remove('is-active');
    });
  },
  initArrows() {
    if (this.options.slidesCount === 0) {
      this.hideElement('left');
      this.hideElement('right');

      return;
    }

    this.options.activeSlide < this.options.slidesCount - 1
      ? this.showElement('right')
      : this.hideElement('right');

    this.options.activeSlide > 0
      ? this.showElement('left')
      : this.hideElement('left');

    this.options.activeSlide < this.options.slidesCount - 1
      ? this.getElement('right').classList.add('is-wide')
      : this.getElement('right').classList.remove('is-wide');

    this.options.activeSlide === this.options.slidesCount - 1
      ? this.getElement('left').classList.add('is-wide')
      : this.getElement('left').classList.remove('is-wide');
  },
  showElement(elementName) {
    this.getElement(elementName).style.display = 'block';
  },
  hideElement(elementName) {
    this.getElement(elementName).style.display = 'none';
  },
};

document.body.addEventListener(
  'page-content-changed',
  HowToBlockSlider.init.bind(HowToBlockSlider),
);
